import React, { useState, useEffect, useMemo } from 'react'

import Select from '../select-search'

import styles from './style.module.scss'

import * as primaryPalette from '../../styles/primary-pallete'

import cn from 'classnames'

import { IoIosPin } from 'react-icons/io'

import PriceRange, { extractPriceRange } from './price-range'

import PropertyCard from '../property-card'

import Pagination, { paginate, paginateText } from '../pagination'

import format from '../format'

import Button from '../button'

import { isArray, cloneDeep, get, isEmpty } from 'lodash-es'

const SEARCH_BOX_FILTERS_KEY = 'SEARCH_BOX_FILTERS_KEY'

const sizeOptions = [
    { value: null, label: 'All sizes' },
    {
        value: '300',
        label: 'Up to ' + format({ type: 'size', value: 300 })
    },
    {
        value: '500-1000',
        label:
            format({ type: 'size', value: 500 }) +
            ' - ' +
            format({ type: 'size', value: 1000 })
    },
    {
        value: '1000-3000',
        label:
            format({ type: 'size', value: 1000 }) +
            ' - ' +
            format({ type: 'size', value: 3000 })
    },
    {
        value: '3000-1000000000',
        label: '+' + format({ type: 'size', value: 3000 })
    }
]

const orderOptions = [
    {
        value: 'price-low-high',
        label: 'Price (low to high)'
    },
    {
        value: 'price-high-low',
        label: 'Price (high to low)'
    },
    {
        value: 'size-small-big',
        label: 'Size (small to big)'
    },
    {
        value: 'size-big-small',
        label: 'Size (big to small)'
    }
]

function filterProperties(data, filter) {
    const properties = data.allWordpressWpProperty.nodes

    const notAvailableStatusId = data.allWordpressWpStatus.nodes.find(
        s => s.slug === 'not-available'
    ).wordpress_id

    return properties.filter(property => {
        if (isEmpty(filter)) {
            return !property.status.find(s => s === notAvailableStatusId)
        }

        let matched = true

        if (!isEmpty(filter.location) && filter.location.value !== null) {
            matched =
                matched &&
                !!property.location.find(l => l === filter.location.value)
        }

        if (!isEmpty(filter.types)) {
            matched =
                matched &&
                !!property.type.find(type => {
                    return filter.types.find(t => t.value === type)
                })
        }

        if (!isEmpty(filter.size) && !isEmpty(filter.size.value)) {
            let sizes = filter.size.value.split('-')
            let start = +sizes[0]
            let end = +sizes[1]

            // if no end specified, start is maximum
            if (!isEmpty(end)) {
                matched = matched && property.acf.size <= start
            } else {
                matched =
                    matched &&
                    property.acf.size >= start &&
                    property.acf.size <= end
            }
        }

        if (!isEmpty(filter.priceRange)) {
            let start = filter.priceRange.value.from
            let end = filter.priceRange.value.to

            matched =
                matched &&
                property.acf.price >= start &&
                property.acf.price <= end
        }

        if (!isEmpty(filter.status) && filter.status.value !== null) {
            matched =
                matched &&
                !!property.status.find(l => l === filter.status.value)
        }

        // hide not available status
        matched =
            matched && !property.status.find(s => s === notAvailableStatusId)

        return matched
    })
}

function getProperties({ data, filter }) {
    if (data) return filterProperties(data, filter)
    return []
}

function getLocations(data) {
    const locations = data.allWordpressWpLocation.nodes.map(node => ({
        value: node.wordpress_id,
        label: node.name.replace('&amp;', '&')
    }))
    locations.unshift({
        value: null,
        label: 'All locations'
    })
    return locations
}

function getStatuses(data) {
    const statuses = data.allWordpressWpStatus.nodes
        .filter(node => node.slug !== 'not-available')
        .map(node => ({
            value: node.wordpress_id,
            label: <span dangerouslySetInnerHTML={{ __html: node.name }} />
        }))

    statuses.unshift({
        value: null,
        label: 'All statuses'
    })

    return statuses
}

function getTypes(data) {
    const types = data.allWordpressWpType.nodes.map(node => ({
        value: node.wordpress_id,
        label: <span dangerouslySetInnerHTML={{ __html: node.name }} />
    }))

    return types
}

function orderProperties({ properties, sortOrder, data }) {
    return properties.sort((p1, p2) => {
        switch (sortOrder.value) {
            case 'price-low-high':
                if (+p1.acf.price < +p2.acf.price) return -1
                else if (+p1.acf.price > +p2.acf.price) return 1
                return 0
            case 'price-high-low':
                if (+p1.acf.price > +p2.acf.price) return -1
                else if (+p1.acf.price < +p2.acf.price) return 1
                return 0
            case 'size-small-big':
                if (+p1.acf.size < +p2.acf.size) return -1
                else if (+p1.acf.size > +p2.acf.size) return 1
                return 0
            case 'size-big-small':
                if (+p1.acf.size > +p2.acf.size) return -1
                else if (+p1.acf.size < +p2.acf.size) return 1
                return 0
            default: {
                const d1 = new Date(p1.date),
                    d2 = new Date(p2.date)
                if (d2 < d1) return -1
                else if (d2 > d1) return 1
                return 0
            }
        }
    })
}

export const SearchBox = ({ data }) => {
    const [filter, setFilter] = useState({})

    const locations = useMemo(() => getLocations(data), [data])

    const types = useMemo(() => getTypes(data), [data])

    const statuses = useMemo(() => getStatuses(data), [data])

    const updateFilter = _data => {
        const data = cloneDeep(_data)

        function getLabel(elem) {
            const label = get(
                elem,
                'label.props.dangerouslySetInnerHTML.__html'
            )
            return label || elem.label
        }

        for (const key of Object.keys(data)) {
            const filterValue = data[key]

            if (isArray(filterValue)) {
                for (const elem of filterValue) {
                    if (get(elem, 'label.props')) {
                        elem.label = getLabel(elem)
                    }
                }
            } else if (filterValue) {
                filterValue.label = getLabel(filterValue)
            }
        }

        setFilter({ ...filter, ...data })
    }

    useEffect(() => {
        setTimeout(() => {
            if (typeof window !== 'undefined')
                if (!isEmpty(filter))
                    sessionStorage[SEARCH_BOX_FILTERS_KEY] = JSON.stringify(
                        filter
                    )
        }, 50)
    }, [filter])
    return (
        <div className={cn(styles.filtersRow, styles.searchBoxFilters)}>
            <div className="row middle-md">
                <div className="col-xs-12 col-sm-4 col-md-3">
                    <Select
                        options={locations}
                        className={styles.select}
                        placeholder="Search locations"
                        onChange={location =>
                            updateFilter({
                                location
                            })
                        }
                    />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-2">
                    <Select
                        options={types}
                        className={styles.select}
                        placeholder="All types"
                        isMulti
                        onChange={types => updateFilter({ types })}
                    />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-2">
                    <Select
                        options={sizeOptions}
                        className={styles.select}
                        placeholder="All sizes"
                        onChange={size => updateFilter({ size })}
                    />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-2">
                    <Select
                        options={statuses}
                        className={styles.select}
                        placeholder="Status"
                        onChange={status => updateFilter({ status })}
                    />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-2">
                    <PriceRange
                        className={styles.select}
                        statuses={statuses}
                        filter={filter}
                        onChange={priceRange => updateFilter({ priceRange })}
                    />
                </div>
                <div
                    className={cn('col-xs-12 col-sm-4 col-md-1', styles.goCol)}
                >
                    <Button
                        link="/properties-and-enquiries"
                        className={styles.goButton}
                    >
                        Go
                    </Button>
                </div>
            </div>
        </div>
    )
}

function getSavedFilters() {
    if (typeof window !== undefined) {
        try {
            return JSON.parse(sessionStorage[SEARCH_BOX_FILTERS_KEY])
        } catch (ex) {
            return {}
        }
    }
    return {}
}

export default ({ data }) => {
    const [showFilters, setShowFilters] = useState(false)

    const [filter, setFilter] = useState(getSavedFilters())

    const [sortOrder, setSortOrder] = useState({})

    const locations = useMemo(() => getLocations(data), [data])

    const types = useMemo(() => getTypes(data), [data])

    const statuses = useMemo(() => getStatuses(data), [data])

    const updateFilter = _data => {
        const data = cloneDeep(_data)

        function getLabel(elem) {
            const label = get(
                elem,
                'label.props.dangerouslySetInnerHTML.__html'
            )
            return label || elem.label
        }

        for (const key of Object.keys(data)) {
            const filterValue = data[key]

            if (isArray(filterValue)) {
                for (const elem of filterValue) {
                    if (get(elem, 'label.props')) {
                        elem.label = getLabel(elem)
                    }
                }
            } else if (filterValue) {
                filterValue.label = getLabel(filterValue)
            }
        }

        setFilter({ ...filter, ...data })
        setCurrentPage(1)
    }

    const [currentPage, setCurrentPage] = useState(1)

    const pageSize = 12

    let properties = useMemo(() => getProperties({ data, filter }), [
        data,
        filter
    ])

    const totalPropertiesCount = properties.length

    properties = orderProperties({ properties, sortOrder, data })

    const { array: paginated, numPages } = paginate({
        array: properties,
        currentPage,
        pageSize
    })

    properties = paginated

    useEffect(() => {
        if (typeof window !== 'undefined') {
            let filter
            try {
                filter = JSON.parse(sessionStorage[SEARCH_BOX_FILTERS_KEY])
            } catch (ex) {}

            if (!isEmpty(filter)) setFilter(filter)
        }
    }, [])

    return (
        <div className="wrapper">
            <div className="row">
                <div className="col-xs-6 col-md-12">
                    <h1 className={styles.pageTitle}>All Properties</h1>
                </div>
                <div className="col-xs-6 col-md-12">
                    <Button
                        className={styles.filtersButton}
                        onClick={() => setShowFilters(!showFilters)}
                    >
                        {showFilters ? 'Hide' : 'Show'} filters
                    </Button>
                </div>
            </div>

            <div
                className={cn(styles.filtersRow, {
                    [styles.showFilters]: showFilters
                })}
            >
                <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-2">
                        <Select
                            options={locations}
                            className={styles.select}
                            placeholder="Search locations"
                            defaultValue={filter.location}
                            onChange={location =>
                                updateFilter({
                                    location
                                })
                            }
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-2">
                        <Select
                            options={types}
                            className={styles.select}
                            placeholder="All types"
                            defaultValue={filter.types}
                            isMulti
                            onChange={types => updateFilter({ types })}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-2">
                        <Select
                            options={sizeOptions}
                            className={styles.select}
                            defaultValue={filter.size}
                            placeholder="All sizes"
                            onChange={size => updateFilter({ size })}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-2">
                        <Select
                            defaultValue={filter.status}
                            options={statuses}
                            className={styles.select}
                            placeholder="Status"
                            onChange={status => updateFilter({ status })}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-2">
                        <PriceRange
                            defaultValue={filter.priceRange}
                            filter={filter}
                            statuses={statuses}
                            className={styles.select}
                            onChange={priceRange =>
                                updateFilter({ priceRange })
                            }
                        />
                    </div>

                    <div className="col-xs-12 col-sm-4 col-md-2">
                        <Select
                            options={orderOptions}
                            className={styles.select}
                            isSearchable={false}
                            placeholder="Default order"
                            onChange={sortOrder => setSortOrder(sortOrder)}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.infoRow}>
                <div className="wrapper">
                    <div className="row">
                        <div className="col-xs-12">
                            <p
                                className={cn(styles.noMarginTop, {
                                    [styles.noMarginBottom]: properties.length
                                })}
                            >
                                {paginateText({
                                    pageSize,
                                    currentPage,
                                    numPages,
                                    count: totalPropertiesCount,
                                    entity: 'property'
                                })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                {properties.map((p, i) => (
                    <div className="col-xs-12 col-md-4">
                        <PropertyCard data={data} key={i} property={p} />
                    </div>
                ))}
            </div>
            <div className="row ">
                <div className="col-xs-12">
                    <Pagination
                        numPages={numPages}
                        currentPage={currentPage}
                        onChange={setCurrentPage}
                    />
                </div>
            </div>
        </div>
    )
}
