import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import PropertiesEnquiries from '../components/properties-enquiries'

export default ({ data }) => {
    let page = data.allWordpressPage.edges[0].node

    return (
        <Layout
            breadcrumb={[
                { title: 'Home', link: '/' },
                { title: 'Properties and enquiries' }
            ]}
            marketTicker
            noSecondaryHeader
        >
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <PropertiesEnquiries data={data} />
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressWpProperty(sort: { fields: date, order: DESC }) {
            nodes {
                wordpress_id
                title
                slug
                content
                location
                broker
                status
                type
                date
                featured_media {
                    alt_text
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 900) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                acf {
                    price
                    size
                    number_of_rooms
                    year
                }
            }
        }
        allWordpressWpStatus {
            nodes {
                wordpress_id
                slug
                name
            }
        }
        allWordpressWpType {
            nodes {
                name
                wordpress_id
            }
        }
        allWordpressWpLocation {
            nodes {
                name
                wordpress_id
            }
        }
        allWordpressWpBroker {
            nodes {
                name
                wordpress_id
            }
        }
        allWordpressPage(filter: { slug: { eq: "properties-and-enquiries" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
