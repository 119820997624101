import React, { useMemo } from 'react'
import Select from '../select-search'

const RENT_PRICE_RANGE = [
    {
        from: 0,
        to: 10000,
    },
    {
        from: 10000,
        to: 30000,
    },
    {
        from: 30000,
        to: 60000,
    },
    {
        from: 60000,
        to: 100000,
    },
    {
        from: 60000,
        to: 100000,
    },
    {
        from: 100000,
        to: 200000,
    },
    {
        from: 200000,
        to: 400000,
    },
    {
        from: 400000,
        to: 1000000,
    },
]

const SELL_PRICE_RANGE = [
    {
        from: 0,
        to: 1000000,
    },
    {
        from: 1000000,
        to: 2000000,
    },
    {
        from: 3000000,
        to: 5000000,
    },
    {
        from: 5000000,
        to: 7000000,
    },
    {
        from: 7000000,
        to: 10000000,
    },
    {
        from: 10000000,
        to: 15000000,
    },
    {
        from: 15000000,
        to: 25000000,
    },
]

const DEFAULT_PRICE_RANGE = RENT_PRICE_RANGE

function priceRangeToOptions(priceRange) {
    return priceRange.map((range) => {
        let from = range.from >= 1e3 ? range.from / 1e3 + 'K' : range.from
        from = range.from >= 1e6 ? range.from / 1e6 + 'M' : from
        let to = range.to >= 1e3 ? range.to / 1e3 + 'K' : range.to
        to = range.to >= 1e6 ? range.to / 1e6 + 'M' : to

        return {
            label: `AED ${from} - ${to}`,
            value: range,
        }
    })
}

function getPriceRange({ filter }) {
    if (filter && filter.status) {
        debugger
        if (filter.status.label.match(/rent/i)) {
            return priceRangeToOptions(RENT_PRICE_RANGE)
        }
        return priceRangeToOptions(SELL_PRICE_RANGE)
    }

    return priceRangeToOptions(DEFAULT_PRICE_RANGE)
}

export default ({ filter, onChange, ...rest }) => {
    const options = useMemo(() => getPriceRange({ filter }), [filter])

    return (
        <Select
            onChange={onChange}
            placeholder={'Price range'}
            options={options}
            {...rest}
        ></Select>
    )
}
