import React from 'react'

import OptionalImage from '../optional-image'

import { LinkCard as Card } from '../card'

import styles from './style.module.scss'

import cn from 'classnames'

import { formatter } from '../format'

import pluralize from 'pluralize'

function getName(list, id) {
    if (!isNaN(id))
        return list.nodes.find(elem => elem.wordpress_id === id).name
    // return list.nodes.filter(elem => elem.wordpress_id === )
}

export default ({ data, property }) => {
    return (
        <Card className={styles.propertyCard} to={`/property/${property.slug}`}>
            <OptionalImage
                object={property}
                path="featured_media.localFile.childImageSharp.fluid"
                className={styles.image}
            />
            <h3
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: property.title }}
            />
            <div className={styles.body}>
                <div className="wrapper-fluid">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className={styles.featureTitle}>Type</div>
                            <div
                                className={cn(styles.featureValue)}
                                dangerouslySetInnerHTML={{
                                    __html: getType({ property, data })
                                }}
                            />
                            <div className={styles.featureTitle}>Status</div>
                            <div
                                className={styles.featureValue}
                                dangerouslySetInnerHTML={{
                                    __html: getName(
                                        data.allWordpressWpStatus,
                                        property.status[0]
                                    )
                                }}
                            />
                            <div className={styles.featureTitle}>Listed by</div>
                            <div
                                className={cn(
                                    styles.featureValue,
                                    styles.brokerName,
                                    styles.lengthy
                                )}
                                dangerouslySetInnerHTML={{
                                    __html: getName(
                                        data.allWordpressWpBroker,
                                        property.broker[0]
                                    )
                                }}
                            />
                        </div>
                        <div className={cn('col-xs-6', styles.alignRight)}>
                            <div className={styles.featureTitle}>Size</div>
                            <div className={styles.featureValue}>
                                {formatter.size(property.acf.size, {
                                    long: true
                                })}
                            </div>
                            <div className={styles.featureTitle}>Price</div>
                            <div className={styles.featureValue}>
                                {formatter.price(property.acf.price)}
                            </div>
                            <div className={styles.featureTitle}>
                                Number of bedrooms
                            </div>
                            <div className={styles.featureValue}>
                                {pluralize(
                                    'room',
                                    +property.acf.number_of_rooms,
                                    true
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

function getType({ property, data }) {
    const types = data.allWordpressWpType.nodes.filter(l =>
        property.type.find(_l => _l === l.wordpress_id)
    )
    return types.map(t => t.name).join(', ')
}
